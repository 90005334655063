import './App.css';

function App() {
  return (
    <div className="main">
      <div className='main-image-container'>
        <img src="./images/main-logo.png"/>
        <a href="https://www.youtube.com/@thecryptogym" target="_blank" rel="noreferrer"><h2>YouTube Channel</h2></a>
      </div>
    </div>
  );
}

export default App;
